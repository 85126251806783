import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Banner.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import gadgetSale from "../../../assets/images/Banners/gadget-sale.jpg";
import kitchenSale from "../../../assets/images/Banners/kitchen-sale.jpg";
import poco from "../../../assets/images/Banners/poco-m4-pro.webp";
import realme from "../../../assets/images/Banners/realme-9-pro.webp";
import fashionSale from "../../../assets/images/Banners/fashionsale.jpg";
import oppo from "../../../assets/images/Banners/oppo-reno7.webp";
import { useEffect, useState } from "react";
import axios from "axios";

export const PreviousBtn = ({ className, onClick }) => {
   return (
      <div className={className} onClick={onClick}>
         <ArrowBackIosIcon />
      </div>
   );
};

export const NextBtn = ({ className, onClick }) => {
   return (
      <div className={className} onClick={onClick}>
         <ArrowForwardIosIcon />
      </div>
   );
};

const Banner = () => {
   const settings = {
      autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PreviousBtn />,
      nextArrow: <NextBtn />,
   };

   const banners = [gadgetSale, kitchenSale, poco, fashionSale, realme, oppo];

   const [bannerList, setBannerList] = useState([]);

   const fetchBannerList = async () => {
      try {
         const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/benner`);

         console.log(data);
         if (data && data?.success) {
            setBannerList(data?.benners || []);
         }
      } catch (err) {
         console.error(err);
      }
   };

   useEffect(() => {
      fetchBannerList();
   }, []);
   return (
      <>
         <section className="h-44 sm:h-72 w-full rounded-sm shadow relative overflow-hidden mm:-mt-12 sm:mt-2">
            <Slider {...settings}>
               {bannerList.map((el, i) => (
                  <img
                     draggable="false"
                     className="h-44 sm:h-72 w-full object-cover"
                     src={el?.image}
                     alt="banner"
                     key={i}
                  />
               ))}
            </Slider>
         </section>
      </>
   );
};

export default Banner;
