import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => { 
    return ( 
        <div className="flex items-center justify-center">
            <CircularProgress />
        </div>
    );
}; 

export default Loading;
