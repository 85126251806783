export const ALL_PRODUCTS_REQUEST = "ALL_PRODUCTS_REQUEST";
export const ALL_PRODUCTS_SUCCESS = "ALL_PRODUCTS_SUCCESS";
export const ALL_PRODUCTS_FAIL = "ALL_PRODUCTS_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";

export const ADMIN_PRODUCTS_REQUEST = "ADMIN_PRODUCTS_REQUEST";
export const ADMIN_PRODUCTS_SUCCESS = "ADMIN_PRODUCTS_SUCCESS";
export const ADMIN_PRODUCTS_FAIL = "ADMIN_PRODUCTS_FAIL";

export const NEW_PRODUCT_REQUEST = "NEW_PRODUCT_REQUEST";
export const NEW_PRODUCT_SUCCESS = "NEW_PRODUCT_SUCCESS";
export const NEW_PRODUCT_RESET = "NEW_PRODUCT_RESET";
export const NEW_PRODUCT_FAIL = "NEW_PRODUCT_FAIL";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_RESET = "DELETE_PRODUCT_RESET";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const ALL_REVIEWS_REQUEST = "ALL_REVIEWS_REQUEST";
export const ALL_REVIEWS_SUCCESS = "ALL_REVIEWS_SUCCESS";
export const ALL_REVIEWS_FAIL = "ALL_REVIEWS_FAIL";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_RESET = "DELETE_REVIEW_RESET";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";

export const SLIDER_PRODUCTS_REQUEST = "SLIDER_PRODUCTS_REQUEST";
export const SLIDER_PRODUCTS_SUCCESS = "SLIDER_PRODUCTS_SUCCESS";
export const SLIDER_PRODUCTS_FAIL = "SLIDER_PRODUCTS_FAIL";

export const REMOVE_PRODUCT_DETAILS = "REMOVE_PRODUCT_DETAILS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const IMAGE_UPLOAD_REQ = "IMAGE_UPLOAD_REQ";
export const IMAGE_UPLOAD_FAILED = "IMAGE_UPLOAD_FAILED";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";

export const CATEGORY_LIST = "IMAGE_UPLOAD_SUCCESS";


export const sizesLabel = ["M", "L", "XL", "2XL", "3XL", "4XL"];
export const sizesLabel1 = ["0-O.5Y", "0.5-1Y", "1-2Y", "2-3Y", "3-4Y", "4-5Y", "5-6Y", "6-7Y", "7-8Y", "8-9Y", "9-10Y"];
export const sizesLabel2 = [ "5" ,"6" , "7", "8", "9", "10", "11", "12"];
